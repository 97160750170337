import { useRouter } from 'next/navigation'
import Banner from '@/components/RollingBanner/Banner'
import { S3_IMAGE_URL } from '@/config'
import { URL_PATH } from '@/consts'
import Wrapper from './Wrapper'

/**
 * 진료 노트 유도 배너
 */
const AppointmentNoteNudge = ({ gaSelector }: { gaSelector: string }) => {
  const { push } = useRouter()

  const handleClick = () => {
    push(URL_PATH.MyNotes)
  }

  return (
    <Wrapper>
      <Banner
        imgSrc={`${S3_IMAGE_URL}/main_banner_ai_notes.jpg`}
        gradient="bg-main-banner-appointment-gradient"
        imgAlt="AI 진료 노트"
        gaSelector={gaSelector}
        title={`진료 녹음 파일\nAI로 깔끔하게 요약`}
        onClick={handleClick}
      />
    </Wrapper>
  )
}

export default AppointmentNoteNudge
